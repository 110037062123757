import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import * as CloudSecurityAuditStyles from '../styles/securityAudits.module.css'
import SEO from 'components/seo'
import background from '../images/circle-stars-secops.svg'
import { isMobile } from 'react-device-detect'

export const query = graphql`
  query {
    wordPress {
      pages(where: {name: "On Prem Infrastructure Audit"}) {
        edges {
          node {
            headerSection {
              heroHeading
              heroText
            }
            securitySubpageFieldGroup {
              approachHeader
              approachDescription
              deliverablesHeader
              deliverablesRepeater {
                deliverablesSubheading
                deliverablesDescription
              }
              deliverablesText
              pricingHeader
              pricingDescription
              pricingRepeater {
                pricingSubheading
                pricingList
              }
              pricingText
              processHeader
              processDescription
                processList {
                processListImage {
                  sourceUrl
                }
                processListHeader
                processListDescription
              }
            }
          }
        }
      }
    }
  }
`

const onPremInfrastructureAudit = ({ data }) => {
  const onPremInfrastructureAuditData =
    data.wordPress.pages.edges[0].node.securitySubpageFieldGroup

  const deliverableData = onPremInfrastructureAuditData.deliverablesRepeater
  const DeliverableList = ({ deliverableData } = []) => {
    return (
      deliverableData.length &&
      deliverableData.map((item, index) => {
        return (
          <div key={index}>
            <ul>
              <li>
                <h4>{item.deliverablesSubheading}</h4>
                {parse(item.deliverablesDescription)}
              </li>
            </ul>
          </div>
        )
      })
    )
  }

  const pricingData = onPremInfrastructureAuditData.pricingRepeater
  const PricingList = ({ pricingData } = []) => {
    return (
      pricingData.length &&
      pricingData.map((item, index) => {
        return (
          <div
            className=''
            key={index}>
            <h4>{item.pricingSubheading}</h4>
            {parse(item.pricingList)}
          </div>
        )
      })
    )
  }

  const processData = onPremInfrastructureAuditData.processList
  const processList = ({ processData } = []) => {
    return (
      processData.length &&
      processData.map((item, index) => {
        return (
          <div
            className={CloudSecurityAuditStyles.flexColumn}
            key={index}>
            <img
              src={item.processListImage.sourceUrl}
            />
            <h4>{item.processListHeader}</h4>
            {parse(item.processListDescription)}
          </div>
        )
      })
    )
  }

  return (
    <>
      <SEO
        description='This page explains Deimos’ on Prem Infrastructure Audits'
        title='On Prem Infrastructure Audit'
      />
      <div className={CloudSecurityAuditStyles.contentContainer}>
        <div
          className={CloudSecurityAuditStyles.mainBackground}
          style={{
            backgroundImage: `url(${isMobile ? '' : background
            })`
          }}>
          <div className={CloudSecurityAuditStyles.mainContainer}>
            <div className={CloudSecurityAuditStyles.csaHeader}>
              <h1>
                {data.wordPress.pages.edges[0].node.headerSection.heroHeading}
              </h1>
              <p>{data.wordPress.pages.edges[0].node.headerSection.heroText}</p>
            </div>
            <div className={CloudSecurityAuditStyles.approachSection}>
              <h2>{onPremInfrastructureAuditData.approachHeader}</h2>
              {parse(onPremInfrastructureAuditData.approachDescription)}
            </div>
            <div className={CloudSecurityAuditStyles.deliverableSection}>
              <h2>{onPremInfrastructureAuditData.deliverablesHeader}</h2>
              {DeliverableList({ deliverableData })}
              {parse(onPremInfrastructureAuditData.deliverablesText)}
            </div>
            <div className={CloudSecurityAuditStyles.pricingSection}>
              <h2>{onPremInfrastructureAuditData.pricingHeader}</h2>
              {parse(onPremInfrastructureAuditData.pricingDescription)}
              {PricingList({ pricingData })}
              {parse(onPremInfrastructureAuditData.pricingText)}
            </div>
            <div
              className={CloudSecurityAuditStyles.processSection}
            >
              <h2>{onPremInfrastructureAuditData.processHeader}</h2>
              {parse(onPremInfrastructureAuditData.processDescription)}
            </div>
            <div className={CloudSecurityAuditStyles.flexItem}>
              {processList({ processData })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

onPremInfrastructureAudit.propTypes = {
  data: PropTypes.object.isRequired
}

export default onPremInfrastructureAudit
